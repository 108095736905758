import * as React from "react";

function SvgMoney(props) {
  return (
    <svg viewBox="0 0 424.98 424.98" {...props}>
      <path fill={props.fill} d="M241.395 282.304c-1.587-1.738-3.595-3.038-5.67-4.121-4.518-2.356-9.459-3.785-14.365-5.075v38.016c7.963-.9 17.105-3.79 21.286-11.224 1.996-3.551 2.393-7.914 1.58-11.867-.441-2.142-1.352-4.108-2.831-5.729z" />
      <path fill={props.fill} d="M242.604 299.973l.042-.073a4.74 4.74 0 00-.042.073zM184.009 221.532c-1.369 1.999-2.228 4.27-2.465 6.684-.237 2.419-.104 5.11.815 7.387.875 2.17 2.708 3.772 4.6 5.062 2.123 1.444 4.458 2.572 6.836 3.528 1.995.803 4.239 1.571 6.658 2.313v-34.4c-6.111 1.304-12.788 4.088-16.444 9.426zM242.804 299.619c-.05.089-.104.182-.157.28.062-.114.111-.198.157-.28zM243.004 299.263c.013-.024.015-.026 0 0z" />
      <path fill={props.fill} d="M234.753 92.469c32.329-27.158 53.931-88.341 40.637-91.017-17.664-3.557-56.022 12.04-74.562 14.788-26.296 3.175-54.936-28.515-71.012-10.851-13.071 14.362 9.371 66.592 44.482 89.346-104.752 51.484-251.988 309.938 4.873 328.691 355.411 25.949 177.444-280.787 55.582-330.957zm30.523 203.829c-1.093 10.076-6.433 19.188-14.415 25.374-8.428 6.532-18.999 9.57-29.502 10.421v11.133c0 2.979-1.301 5.86-3.531 7.832-3.065 2.712-7.569 3.381-11.289 1.667-3.673-1.69-6.086-5.457-6.086-9.499v-12.168a68.538 68.538 0 01-5.356-1.234c-9.816-2.697-18.921-7.954-25.572-15.732-3.313-3.877-6.014-8.276-7.882-13.025a47.162 47.162 0 01-1.304-3.783c-.345-1.157-.701-2.333-.824-3.539a10.46 10.46 0 011.137-5.889c1.938-3.707 6.022-5.946 10.192-5.574 4.104.364 7.701 3.212 8.993 7.124.398 1.205.668 2.44 1.115 3.632a23.292 23.292 0 001.607 3.431 26.647 26.647 0 004.59 5.875c3.694 3.559 8.399 5.872 13.304 7.248V268.23c-9.591-2.483-19.491-5.69-27.411-11.848-3.849-2.994-7.115-6.714-9.254-11.117-2.257-4.647-3.192-9.824-3.23-14.966-.039-5.221.953-10.396 3.131-15.153 2.04-4.454 4.977-8.453 8.578-11.768 7.7-7.087 17.928-11.04 28.187-12.492v-11.557c0-2.978 1.301-5.86 3.531-7.832 3.066-2.711 7.568-3.381 11.289-1.667 3.672 1.691 6.086 5.457 6.086 9.499v11.494c1.367.172 2.73.378 4.086.624 10.074 1.823 19.927 5.983 27.294 13.246a39.888 39.888 0 018.356 12.009 38.31 38.31 0 011.477 3.824c.396 1.213.794 2.462.983 3.728a10.467 10.467 0 01-.871 5.958c-1.772 3.788-5.746 6.2-9.927 6.021-4.108-.179-7.83-2.854-9.301-6.694-.438-1.142-.657-2.351-1.104-3.49a18.419 18.419 0 00-1.708-3.292c-1.308-2.02-3.003-3.752-4.938-5.179-4.19-3.094-9.272-4.706-14.35-5.607v39.582c6.035 1.445 12.075 3.021 17.857 5.301 8.739 3.446 17.02 8.73 21.79 17.062-.74-1.298-1.46-2.563.025.043 1.458 2.56.762 1.34.03.057 3.795 6.688 5.042 14.685 4.217 22.282z" />
      <path fill={props.fill} d="M242.493 300.169l-.156.278c.036-.063.09-.158.156-.278z" />
    </svg>
  );
}

export default SvgMoney;
