export const SET_SOCIOS = 'SET_SOCIOS'
export const SET_SEL_SOCIO = 'SET_SEL_SOCIO'
export const FETCH_SOCIOS_FAILED = 'FETCH_SOCIOS_FAILED'
export const UNSELECT_SOCIO = 'UNSELECT_SOCIO'
export const UPDATE_SOCIO_SUCCESS = 'UPDATE_SOCIO_SUCCESS'
export const UPDATE_SOCIO_FAILED = 'UPDATE_SOCIO_FAILED'
export const UPDATE_SOCIO_START = 'UPDATE_SOCIO_START'
export const NEW_SOCIO = 'NEW_SOCIO'
export const NEW_SOCIO_SUCCESS = 'NEW_SOCIO_SUCCESS'
export const NEW_SOCIO_FAILED = 'NEW_SOCIO_FAILED'
export const NEW_SOCIO_START = 'NEW_SOCIO_START'
export const SET_SOCIO_SALDO = 'SET_SOCIO_SALDO'
export const GET_SOCIO_SALDO_FAILED = 'GET_SOCIO_SALDO_FAILED'
export const CLEAR_SOCIO_SALDO = 'CLEAR_SOCIO_SALDO'

export const SET_SOLICITUDES = 'SET_SOLICITUDES'
export const FETCH_SOLICITUDES_FAILED = 'FETCH_SOLICITUDES_FAILED'

export const SET_SEL_SOLICITUD = 'SET_SEL_SOLICITUD'
export const UNSELECT_SOLICITUD = 'UNSELECT_SOLICITUD'
export const NEW_SOLICITUD = 'NEW_SOLICITUD'
export const NEW_SOLICITUD_SUCCESS = 'NEW_SOLICITUD_SUCCESS'
export const NEW_SOLICITUD_FAILED = 'NEW_SOLICITUD_FAILED'
export const NEW_SOLICITUD_START = 'NEW_SOLICITUD_START'

export const SET_MOVIMIENTOS = 'SET_MOVIMIENTOS'
export const UNSET_MOVIMIENTOS = 'UNSET_MOVIMIENTOS'
export const NEW_MOVIMIENTO = 'NEW_MOVIMIENTO'
export const NEW_MOVIMIENTO_SUCCESS = 'NEW_MOVIMIENTO_SUCCESS'
export const NEW_MOVIMIENTO_FAILED = 'NEW_MOVIMIENTO_FAILED'
export const NEW_MOVIMIENTO_START = 'NEW_MOVIMIENTO_START'
export const SET_SEL_MOV = 'SET_SEL_MOV'
export const UNSELECT_MOV = 'UNSELECT_MOV'
export const UPDATE_MOVIMIENTO_SUCCESS = 'UPDATE_MOVIMIENTO_SUCCESS'
export const UPDATE_MOVIMIENTO_FAILED = 'UPDATE_MOVIMIENTO_FAILED'
export const UPDATE_MOVIMIENTO_START = 'UPDATE_MOVIMIENTO_START'

export const SET_CREDITOS = 'SET_CREDITOS'
export const SET_SEL_CONTRATO = 'SET_SEL_CONTRATO'
export const UNSELECT_CONTRATO = 'UNSELECT_CONTRATO'
export const UPDATE_CREDITO_START = 'UPDATE_CREDITO_START'
export const UPDATE_CREDITO_FAILED = 'UPDATE_CREDITO_FAILED'
export const UPDATE_CREDITO_SUCCESS = 'UPDATE_CREDITO_SUCCESS'

export const SET_PAGOS = 'SET_PAGOS'
export const SET_SEL_PAGO = 'SET_SEL_PAGO'
export const UNSELECT_PAGO = 'UNSELECT_PAGO'

export const SET_ACOPIOS = 'SET_ACOPIOS'
export const NEW_ACOPIO = 'NEW_ACOPIO'
export const NEW_ACOPIO_SUCCESS = 'NEW_ACOPIO_SUCCESS'
export const NEW_ACOPIO_FAILED = 'NEW_ACOPIO_FAILED'
export const NEW_ACOPIO_START = 'NEW_ACOPIO_START'

export const SET_REGIONES = 'SET_REGIONES'
export const SET_COMUNIDADES = 'SET_COMUNIDADES'
export const SET_ERMITAS = 'SET_ERMITAS'
export const SET_CARGOS = 'SET_CARGOS'
export const SET_CARGOS_COOP = 'SET_CARGOS_COOP'
export const SET_EMPRESAS = 'SET_EMPRESAS'
export const SET_FUENTES = 'SET_FUENTES'
export const SET_PUESTOS = 'SET_PUESTOS'
export const SET_USER = 'SET_USER'
export const UPDATE_COMUNIDAD_SUCCESS = 'UPDATE_COMUNIDAD_SUCCESS'
export const UPDATE_COMUNIDAD_FAILED = 'UPDATE_COMUNIDAD_FAILED'
export const UPDATE_COMUNIDAD_START = 'UPDATE_COMUNIDAD_START'
export const SET_SEL_COMUNIDAD = 'SET_SEL_COMUNIDAD'
export const UNSELECT_COMUNIDAD = 'UNSELECT_COMUNIDAD'
export const NEW_COMUNIDAD = 'NEW_COMUNIDAD'
export const NEW_COMUNIDAD_SUCCESS = 'NEW_COMUNIDAD_SUCCESS'
export const NEW_COMUNIDAD_FAILED = 'NEW_COMUNIDAD_FAILED'
export const NEW_COMUNIDAD_START = 'NEW_COMUNIDAD_START'

export const CHANGE_LOCALE = 'CHANGE_LOCALE'

export const SET_SEL_LIST = 'SET_SEL_LIST'
export const CLEAR_SEL_LIST = 'CLEAR_SEL_LIST'

export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const USER_LOGOUT = 'USER_LOGOUT'
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'
export const FINISHED_AUTH_REDIRECT = 'FINISHED_AUTH_REDIRECT'
export const ANIMATED_INTRO = 'ANIMATED_INTRO'

export const ERROR_RECEIVED = 'ERROR_RECEIVED'
export const ERROR_CLEAR = 'ERROR_CLEAR'
