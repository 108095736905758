import * as React from "react";

function SvgCoffee(props) {
  return (
    <svg viewBox="0 0 33.058 33.058" {...props}>
      <path
        d="M31.491 5.704c-2.442-1.9-6.377.692-9.808 2.548L26.9 3.65s1.952-.625.554-1.932C21.595-1.7 12.864.056 6.58 6.338c-7.187 7.19-8.451 17.582-2.818 23.213.331.969 2.115-1.596 2.819-2.334-.322.328.837-.87 0 0-.419 1.035-.666 2.644-.809 3.916 5.857 3.687 14.794 2.002 21.198-4.402 6.344-6.34 6.964-19.126 4.521-21.027z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgCoffee;
