const translations = {
  "200": "Ic'al ha'al soc chawuc",
  "201": "Tulan ic' soc ha'al",
  "202": "Tulan ic' soc tulan ha'al",
  "210": "Jte'b nax ic'al ha'al",
  "211": "Ic'al ha'al",
  "212": "Tulan ic'al ha'al ",
  "221": "Jc'axel nax ic'al ha'al",
  "230": "Jte'bnax xmusmon ic'al ha'al",
  "231": "Xmusmon ic'al ha'al",
  "232": "Tulan xmusmon ic'al  ha'al",
  "300": "Jteb nax xmusmun ha'al",
  "301": "Musmus ha'al",
  "302": "Tulan musmus ha'al",
  "310": "Jc'axel nax musmus ha'al",
  "311": "Musmus ha'al",
  "312": "Tulan musmus ha'al",
  "313": "Tulan ic'al ha'al soc musmus ha'al",
  "314": "Yip ic'al ha'al soc musmus ha'al",
  "321": "Qu'ini ha'al",
  "500": "Jc'axel ha'al",
  "501": "Jc'axel c'axel tulan ha'al",
  "502": "Tulan ha'al ",
  "503": "C'ax tulan ha'al",
  "504": "Tulan ha'al soc puhlel",
  "511": "Ha'al soc tulan sic",
  "520": "Pequ'el nax yip ha'al",
  "521": "Tulan ic'al ha'al",
  "522": "Yip ic'al ha'al",
  "531": "Juju ts'in ha'al",
  "600": "Jc'axelnax tulan sic",
  "601": "Tulan sic",
  "602": "C'ax tulan sic",
  "611": "Ha'al soc tulan sic",
  "612": "Tulan ic'al ha'al soc tulan sic",
  "613": "Tulan ic'al ha'al soc tulan sic",
  "615": "Jte'b nax ha'al soc sic",
  "616": "Ha'al soc tulan sic",
  "620": "Jteb nax ic'al ha'al soc tulan sic",
  "621": "Tulan ic'al ha'al soc sic",
  "622": "Cax tulan ic'al ha'al soc sic",
  "701": "But'ul qu'inal",
  "711": "Ch'ajil",
  "721": "But'ul qu'inal",
  "731": "Yic'al tsu'bilum",
  "741": "C'ax but'ul qu'inal",
  "751": "Tsu'bilum",
  "761": "Tanetic ta toyol",
  "762": "Stanul squ'ixnal bahlumilal (Volcan)",
  "771": "Shahchel tulan ic'al ha'al",
  "781": "Sutul ic'",
  "800": "Qu'epel qu'inal",
  "801": "Jte'bnax macal qu'inal",
  "802": "Namajtic ay tocal",
  "803": "Mac mac qu'inal",
  "804": "Macal qu'inal"
}

export default translations
