import * as React from "react";

function SvgSoap(props) {
  return (
    <svg viewBox="0 0 297 297" {...props}>
      <path fill={props.fill} d="M218.756 92.482c18.287 0 33.171-15.062 33.171-33.578 0-18.515-14.884-33.577-33.171-33.577-18.291 0-33.172 15.062-33.172 33.577 0 18.517 14.881 33.578 33.172 33.578zM238.739 126.934c0 15.06 12.251 27.314 27.313 27.314 15.059 0 27.313-12.254 27.313-27.314 0-15.062-12.254-27.316-27.313-27.316-15.062-.001-27.313 12.254-27.313 27.316zM203.484 147.292c1.088 0 2.121-.474 2.831-1.298s1.03-1.918.868-2.993c-3.201-21.317-21.634-37.713-43.826-37.713-14.343 0-27.428 6.797-35.646 17.958a27.448 27.448 0 00-13.822-.112c-8.232-11.087-21.301-17.846-35.561-17.846-21.238 0-39.038 15.022-43.331 35.001C15.023 144.582 0 162.38 0 183.624c0 24.438 19.882 44.322 44.319 44.322a3.736 3.736 0 003.736-3.736v-47.029c0-16.481 13.407-29.889 29.888-29.889z" />
      <path fill={props.fill} d="M287.725 167.828H77.987c-5.104 0-9.276 4.173-9.276 9.274v85.294c0 5.103 4.173 9.276 9.276 9.276h209.737c5.102 0 9.275-4.173 9.275-9.276v-85.294c.001-5.101-4.172-9.274-9.274-9.274z" />
    </svg>
  );
}

export default SvgSoap;
